import React, { useEffect } from 'react';
import { Switch, Route, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import Dashboard from './components/Layout/Dashboard/Dashboard';
import Components from './pages/Components/Components';
import FitnessPlan from './pages/FitnessPlan/FitnessPlan';
import FitnessProgramForm from './pages/FitnessProgramForm/FitnessProgramForm';
import FitnessPrograms from './pages/FitnessPrograms/FitnessPrograms';
import Homepage from './pages/Homepage/Homepage';
import Login from './pages/Login/Login';
import MealPlan from './pages/MealPlan/MealPlan';
import MealPlanMenu from './pages/MealPlanMenu/MealPlanMenu';
import MealPlanMenuDetail from './pages/MealPlanMenuDetail/MealPlanMenuDetail';
import MealPlanShoppingList from './pages/MealPlanShoppingList/MealPlanShoppingList';
import Overview from './pages/Overview/Overview';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import Profile from './pages/Profile/Profile';
import ProfileEdit from './pages/ProfileEdit/ProfileEdit';
import ProfileSetup from './pages/ProfileSetup/ProfileSetup';
import TrainingPlanDetail from './pages/TrainingPlanDetail/TrainingPlanDetail';
import { getCookie } from './services/utils';
import Chat from './pages/App/Chat/Chat';

type RoutesType = {
    path: string;
    title: string;
    exact?: boolean;
    protected: boolean;
    authPage?: boolean;
    component: React.FC<RouteComponentProps>;
    hideenForAccess?: ('MEMBER_SECTION' | 'COACHING' | 'TRAINING_PLAN' | 'PRO')[];
}[];
interface PageType extends RouteComponentProps {
    component: React.FC<any>;
    title: string;
}

declare global {
    interface Window {
        router: any;
    }
}

export const routesURLs = {
    homepage: '/',
    login: '/prihlaseni',
    passwordRecovery: '/obnova-hesla',
    passwordReset: '/reset-hesla',
    profileSetup: '/nastaveni-profilu',
    dashboard: '/dashboard',
    overview: '/dashboard',
    components: '/dashboard/components',
    profile: '/dashboard/profil/',
    profileEdit: '/dashboard/profil/editace',
    fitnessPrograms: '/dashboard/fitness-program/',
    fitnessProgramForm: '/dashboard/fitness-program/:id/formular',
    fitnessPlan: '/dashboard/treninkovy-plan/',
    trainingDetail: '/dashboard/treninkovy-plan/:id',
    mealPlan: '/dashboard/stravovaci-plan/',
    mealPlanMenu: '/dashboard/stravovaci-plan/jidelnicek/',
    mealPlanShoppingList: '/dashboard/stravovaci-plan/nakupni-seznam/',
    appChat: '/app/chat',
};

export const routesList: RoutesType = [
    {
        path: routesURLs.homepage,
        title: 'Úvodní stránka',
        component: Homepage,
        protected: false,
        exact: true,
    },
    {
        path: routesURLs.login,
        title: 'Přihlášení',
        component: Login,
        protected: false,
        authPage: true,
        exact: true,
    },
    {
        path: routesURLs.passwordRecovery,
        title: 'Obnovení hesla',
        component: PasswordRecovery,
        protected: false,
        authPage: true,
        exact: true,
    },
    {
        path: routesURLs.passwordReset,
        title: 'Reset hesla',
        component: PasswordReset,
        protected: false,
        authPage: true,
        exact: true,
    },
    {
        path: routesURLs.profileSetup,
        title: 'Nastavení profilu',
        component: ProfileSetup,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.dashboard,
        title: 'Nástěnka',
        component: Dashboard,
        protected: true,
    },
    {
        path: routesURLs.appChat,
        title: 'Chat',
        component: Chat,
        protected: false,
        exact: true,
    },
];

export const dashboardRoutesList: RoutesType = [
    {
        path: routesURLs.overview,
        title: 'Nástěnka',
        component: Overview,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.profile,
        title: 'Profil',
        component: Profile,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.profileEdit,
        title: 'Editace profilu',
        component: ProfileEdit,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.fitnessPrograms,
        title: 'Fitness program',
        component: FitnessPrograms,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.fitnessProgramForm,
        title: 'Fitness program formulář',
        component: FitnessProgramForm,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.fitnessPlan,
        title: 'Tréninkový plán',
        component: FitnessPlan,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.trainingDetail,
        title: 'Tréninkový plán',
        component: TrainingPlanDetail,
        protected: true,
        exact: true,
    },
    {
        path: routesURLs.mealPlan,
        title: 'Stravovací plán',
        component: MealPlan,
        protected: true,
        exact: true,
        hideenForAccess: ['COACHING', 'TRAINING_PLAN'],
    },
    {
        path: routesURLs.mealPlanMenu,
        title: 'Jídelníček',
        component: MealPlanMenu,
        protected: true,
        exact: true,
        hideenForAccess: ['COACHING', 'TRAINING_PLAN'],
    },
    {
        path: routesURLs.mealPlanMenu + ':id',
        title: 'Recept',
        component: MealPlanMenuDetail,
        protected: true,
        exact: true,
        hideenForAccess: ['COACHING', 'TRAINING_PLAN'],
    },
    {
        path: routesURLs.mealPlanShoppingList,
        title: 'Nákupní seznam',
        component: MealPlanShoppingList,
        protected: true,
        exact: true,
        hideenForAccess: ['COACHING', 'TRAINING_PLAN'],
    },
    {
        path: routesURLs.components,
        title: 'Komponenty',
        component: Components,
        protected: true,
        exact: true,
    },
];

const Routes: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        document.body.setAttribute('route', location.pathname);
    }, [location]);

    return (
        <Switch>
            {routesList.map((route, key) => (
                <Route
                    key={key}
                    path={route.path}
                    exact={route.exact || false}
                    render={(props) => {
                        if (route.authPage && getCookie('jid')) {
                            return <Redirect to={routesURLs.dashboard} />;
                        } else if (route.protected && !getCookie('jid')) {
                            return <Redirect to={routesURLs.homepage} />;
                        } else {
                            return <Page {...props} component={route.component} title={route.title} />;
                        }
                    }}
                />
            ))}
            <Redirect to="/" />
        </Switch>
    );
};

export const Page: React.FC<PageType> = (props) => {
    const { title, component } = props;
    const PageComponent = component;

    if (!window.router) {
        window.router = props.history;
    }

    useEffect(() => {
        document.title = title ? `${title} · Strong beauty` : 'Strong beauty';
    }, [title]);

    return (
        <>
            <div className="page-content">
                <PageComponent {...props} />
            </div>
        </>
    );
};

export default Routes;
