import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TitleTable from '../../components/Layout/TitleTable/TitleTable';
import Button from '../../components/UI/Button/Button';
import IconTitle from '../../components/UI/IconTitle/IconTitle';
import { GlobalTypes, useGlobalState } from '../../contexts/global';
import { logout } from '../../moduls/auth';
import { deleteProfile } from '../../moduls/profile';
import { routesURLs } from '../../Routes';
import { CurrencyType, ProfileSetupValues, SubscriptionType } from '../../services/types';
import './Profile.scss';
import ProfileAvatar from './ProfileAvatar/ProfileAvatar';
import { ReactComponent as CameraIcon } from '../../assets/images/icons/ic-camera.svg';
import ProfileDeleteModal from './ProfileDeleteModal/ProfileDeleteModal';
import ProfileEditImageModal from './ProfileEditImageModal/ProfileEditImageModal';
import useProfile from '../../hooks/useProfile';
import useSWR, { cache, mutate } from 'swr';
import { getSubscriptions, getSubscriptionsData } from '../../moduls/subscriptions';
import { formateNumber } from '../../services/utils';
import ProfileCancelSubscriptionModal from './ProfileCancelSubscriptionModal/ProfileCancelSubscriptionModal';
import ProfileEditSubscriptionDataModal from './ProfileEditSubscriptionDataModal/ProfileEditSubscriptionDataModal';
import ProfileEditParametersModal from './ProfileEditParametersModal/ProfileEditParametersModal';
import useNotification from '../../hooks/useNotification';

const Profile = (props: RouteComponentProps) => {
    const { state, dispatch } = useGlobalState();
    const { data: profile } = useProfile();
    const { data: subscriptions } = useSWR<SubscriptionType[]>('subscriptions', getSubscriptions);
    const [deleteModal, openDeleteModal] = useState(false);
    const [editPhoto, openEditPhoto] = useState(false);
    const [editParametersModal, openEditParametersModal] = useState(false);
    const [cancelSubsModal, openCancelSubsModal] = useState(false);
    const [subsCancelId, setSubsCancelId] = useState(0);
    const [editSubsDataModal, openEditSubsDataModal] = useState(false);
    const { open: openNotification } = useNotification();

    const endUserSession = async (type: 'logout' | 'deleteProfile') => {
        dispatch({ type: GlobalTypes.ToggleLoader, loader: type });
        type === 'logout' ? await logout() : await deleteProfile();
        dispatch({ type: GlobalTypes.ToggleLoader });
        props.history.push(routesURLs.login);
        cache.clear();
    };

    const setCancelSubsModal = (id: number) => {
        setSubsCancelId(id);
        openCancelSubsModal(true);
    };

    const getSubsDataAndOpenEditModal = async () => {
        if (!cache.has('subscription-data')) {
            dispatch({ type: GlobalTypes.ToggleLoader, loader: 'getSubscriptionsData' });
            try{
                await mutate('subscription-data', getSubscriptionsData);
                openEditSubsDataModal(true);
                dispatch({ type: GlobalTypes.ToggleLoader });
            } catch(e) {
                openNotification('Něco se pokazilo','error');
                dispatch({ type: GlobalTypes.ToggleLoader });
            }
        } else {
            openEditSubsDataModal(true);
        }
    };

    if (!profile) return null;

    const lifetimeMember = profile.life_long_member;

    return (
        <>
            <div className="profile dashboard-grid page-row container">
                <div className="dashboard-side-text">
                    <div className="profile-avatar-wrapper">
                        <button type="button" onClick={() => openEditPhoto(true)} className="profile-avatar-edit">
                            <CameraIcon />
                        </button>
                        <ProfileAvatar name={profile.name} photo={profile.profile_photo} fontSize={2} />
                    </div>
                    <h2 className="profile-title">{profile?.name}</h2>
                    <div className="profile-subtitle">{profile?.email}</div>
                    <Button to={routesURLs.profileEdit} color="gray" size="small">
                        Upravit údaje účtu
                    </Button>
                    <div className="profile-controls">
                        <Button
                            onClick={() => endUserSession('logout')}
                            size="link"
                            className="h-color-black"
                            loading={state.loader === 'logout'}
                        >
                            Odhlásit se
                        </Button>
                        {/* <Button onClick={() => openDeleteModal(true)} size="link" className="h-color-medium-gray">
                            Smazat účet
                        </Button> */}
                    </div>
                </div>
                <div className="dashboard-content">
                    <div className="container h-m-0">
                        <TitleTable
                            className="h-mb-25"
                            header={
                                <>
                                    <IconTitle smallIcon={true} icon="person-circle-black">
                                        Parametry účtu
                                    </IconTitle>
                                    <Button onClick={() => openEditParametersModal(true)} color="gray" size="small">
                                        Upravit parametry
                                    </Button>
                                </>
                            }
                        >
                            <thead>
                                <tr>
                                    <th>Položka</th>
                                    <th>Vyplněná odpověď</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Výška</th>
                                    <th>{profile.user_questionnaire?.height} cm</th>
                                </tr>
                                <tr>
                                    <th>Váha</th>
                                    <th>{profile.user_questionnaire?.weight} kg</th>
                                </tr>
                                <tr>
                                    <th>Jídelníček</th>
                                    <th>{ProfileSetupValues[profile.user_questionnaire!.diet_preference]}</th>
                                </tr>
                                <tr>
                                    <th>Kde cvičíš</th>
                                    <th>{profile?.place === 1 ? ProfileSetupValues.FITNESS : ProfileSetupValues.HOME}</th>
                                </tr>
                            </tbody>
                        </TitleTable>
                        <TitleTable
                            className="h-mb-25"
                            header={
                                <>
                                    <IconTitle smallIcon={true} icon="timer-black">
                                        Odcvičeno
                                    </IconTitle>
                                </>
                            }
                        >
                            <thead>
                                <tr>
                                    <th>Položka</th>
                                    <th>Počet</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Dokončených fitness programů</th>
                                    <th>{profile.fitness_programs_count}</th>
                                </tr>
                                <tr>
                                    <th>Odcvičených tréninků</th>
                                    <th>{profile.fitness_trainings_count}</th>
                                </tr>
                            </tbody>
                        </TitleTable>
                        {subscriptions?.length && (
                            <TitleTable
                                header={
                                    <>
                                        <IconTitle smallIcon={true} icon="wallet-black">
                                            Přehled plateb
                                        </IconTitle>
                                        <Button
                                            onClick={getSubsDataAndOpenEditModal}
                                            color="gray"
                                            size="small"
                                            loading={state.loader === 'getSubscriptionsData'}
                                        >
                                            Upravit fakturační údaje
                                        </Button>
                                    </>
                                }
                            >
                                <thead>
                                    <tr>
                                        <th>Položka</th>
                                        <th>Cena</th>
                                        <th>Platnost členství do</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions.map((subs, index) => {
                                        let validTo = subs.valid_to
                                            ? new Date(subs.valid_to).toLocaleDateString('cs-CZ')
                                            : '-';

                                        if (index === 0 && lifetimeMember) {
                                            validTo = 'Doživotní členství';
                                        }

                                        return (
                                            <tr key={subs.id}>
                                                <th>{subs.name}</th>
                                                <th>
                                                    {formateNumber(subs.price / 100, 1)} {CurrencyType[subs.currency]}
                                                </th>
                                                <th>{validTo}</th>
                                                <th className="h-text-right">
                                                    {subs.next_payment_at && (
                                                        <Button onClick={() => setCancelSubsModal(subs.id)} size="link">
                                                            Zrušit předplatné
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </TitleTable>
                        )}
                    </div>
                </div>
            </div>
            <ProfileDeleteModal
                close={() => openDeleteModal(false)}
                isOpen={deleteModal}
                deleteUser={() => endUserSession('deleteProfile')}
                loading={state.loader === 'deleteProfile'}
            />
            <ProfileEditImageModal close={() => openEditPhoto(false)} isOpen={editPhoto} />
            <ProfileCancelSubscriptionModal
                close={() => openCancelSubsModal(false)}
                isOpen={cancelSubsModal}
                id={subsCancelId}
            />
            <ProfileEditSubscriptionDataModal close={() => openEditSubsDataModal(false)} isOpen={editSubsDataModal} />
            <ProfileEditParametersModal close={() => openEditParametersModal(false)} isOpen={editParametersModal} />
        </>
    );
};

export default Profile;
