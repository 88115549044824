import React, { useState } from 'react';

import { getCookie, setCookie } from '../../services/utils';
import playStoreBadge from '../../assets/images/stores/google-play-badge.svg';
import appStoreBadge from '../../assets/images/stores/app-store-badge.svg';

import './AppBanner.scss';

const AppBanner = () => {
    const bannerClosed = getCookie('app-banner-closed');
    const [isOpened, setIsOpened] = useState(bannerClosed !== 'true');
    const playStoreUrl = process.env.REACT_APP_PLAY_STORE_URL;
    const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;

    return isOpened ? (
        <div className="app-banner-wrapper">
            <div className="app-banner-content">
                <div className="app-banner-text">
                    <div className="app-banner-icon-wrapper">
                        <i className={`icon-phone-download`} />
                    </div>
                    Máme i mobilní aplikaci! Stáhni si ji pro pohodlnější používání.
                </div>
                <div className="app-banner-stores">
                    {appStoreUrl && (
                        <a href={appStoreUrl} target="_blank" rel="noreferrer">
                            <img src={appStoreBadge} className="app-banner-store-badge" alt="Play Store" />
                        </a>
                    )}
                    {playStoreUrl && (
                        <a href={playStoreUrl} target="_blank" rel="noreferrer">
                            <img src={playStoreBadge} className="app-banner-store-badge" alt="Play Store" />
                        </a>
                    )}
                    <button
                        className="app-banner-close app-banner-close-desktop"
                        onClick={() => {
                            setIsOpened(false);
                            setCookie('app-banner-closed', 'true', 365);
                        }}
                    >
                        <i className="icon-cross-black" />
                    </button>
                </div>
            </div>
            <button
                className="app-banner-close app-banner-close-mobile"
                onClick={() => {
                    setIsOpened(false);
                    setCookie('app-banner-closed', 'true', 365);
                }}
            >
                <i className="icon-cross-black" />
            </button>
        </div>
    ) : null;
};

export default AppBanner;
