import React, { useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR, { cache } from 'swr';
import RadioInput from '../../components/Form/Inputs/RadioInput/RadioInput';
import BackButton from '../../components/UI/Button/BackButton';
import {
    FitnessProgramGuestions,
    getFitnessPlans,
    getFitnessProgramsQuestions,
    selectFitnessPlan, setFitnessPlanFirstDay,
} from '../../moduls/trainings';
import '../FitnessPrograms/FitnessPrograms.scss';
import { ReactComponent as ChevronDown } from '../../assets/images/icons/ic-chevron-down-black.svg';
import Toggler from '../../components/UI/Toggler/Toggler';
import Accordion from '../../components/UI/Accordion/Accordion';
import { classes, isSafari } from '../../services/utils';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/Modal/Modal';
import { routesURLs } from '../../Routes';
import useProfile from '../../hooks/useProfile';
import useNotification from '../../hooks/useNotification';
import { ProfileDataType } from '../../services/types';
import { WarningCircle } from '../../components/Svg/Icons';
import { Form, Formik } from 'formik';

const FitnessProgramForm = () => {
    const { id }: { id: string } = useParams();
    const history = useHistory();

    const [value, setValue] = useState<Record<number, number>>({});
    const [changeModal, setChangeModal] = useState(false);
    const [extraWarningModal, setExtraWarningModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [datePickModal, setDatePickModal] = useState(false);
    const [startDate, setStartDate] = useState<string | null>(null);

    const dateInputRef = useRef<HTMLInputElement>(null);

    const { open: openNotification } = useNotification();
    const { data: profile, mutate: updateProfile } = useProfile();
    const { data: questions, isValidating } = useSWR<FitnessProgramGuestions[]>(
        ['fitness-programs-questions', id],
        getFitnessProgramsQuestions,
        {
            revalidateOnMount: !cache.has(['fitness-programs-questions', id]),
        },
    );

    const { data: plans } = useSWR<{ id: number; answers: { id: number }[] }[]>(
        ['fitness-plans', id],
        getFitnessPlans,
        {
            revalidateOnMount: !cache.has(['fitness-plans', id]),
        },
    );

    const selectedPlans = useMemo(() => {
        const values = Object.values(value);
        const filteredPlans = values.length
            ? plans?.filter((plan) => plan.answers.filter((r) => values.indexOf(r.id) >= 0).length === values.length)
            : // ? plans?.filter((plan) => plan.answers.some((r) => values.indexOf(r.id) >= 0))
            plans;
        const filteredPlansAnswers = filteredPlans
            ?.map((plan) => plan.answers)
            .flat(1)
            .map((answer) => answer.id);
        return {
            plans: filteredPlans,
            answers: filteredPlansAnswers,
        };
    }, [value, plans]);

    const submit = () => {
        setDatePickModal(true);
    };

    const selectPlan = async () => {
        let index = 0;
        let matches = 0;
        selectedPlans.plans!.forEach((plan, i) => {
            const tempMatches = plan.answers?.filter((answer) => Object.values(value).indexOf(answer.id) >= 0).length;
            if (tempMatches > matches) {
                matches = tempMatches;
                index = i;
            }
        });
        const planId = selectedPlans.plans![index].id;

        if (!planId) {
            return openNotification('Žádný tréninkový plán nevyhovuje těmto kritériím.');
        }
        setLoadingModal(true);
        setChangeModal(false);
        selectFitnessPlan(planId)
            .then(() => {
                const dateFormat = new Date(startDate!);
                setFitnessPlanFirstDay(planId, dateFormat.toISOString()).then(() => {
                    setTimeout(() => {
                        updateProfile({ ...(profile as ProfileDataType), fitness_plan_id: planId }, true);
                        setLoadingModal(false);
                        openNotification('Program byl sestaven!', 'success');
                        history.replace(routesURLs.dashboard);
                    }, 4000);
                });
            })
            .catch((error) => {
                setLoadingModal(false);
                openNotification(error.response.data.message);
            });
    };

    return (
        <>
            <div className='fitness-programs-questions dashboard-grid page-row container'>
                <div className='dashboard-side-text'>
                    <BackButton className='h-mb-sm-2' />
                    <div className='overview-text'>
                        <h1>Dotazník k&nbsp;programu</h1>
                    </div>
                </div>
                <div className='dashboard-content'>
                    {isValidating ? (
                        <div className='h-py-5 h-text-center'>
                            <span className='loader-spinner' />
                        </div>
                    ) : (
                        <>
                            {questions?.map(({ question, description, answers, id }, index) => {
                                return (
                                    <div key={id} className='fitness-programs-question'>
                                        <Toggler>
                                            {({ open, setOpen }) => (
                                                <>
                                                    <h3
                                                        onClick={() => description && setOpen(!open)}
                                                        className={classes('', { 'h-cursor-pointer': !!description })}
                                                    >
                                                        {question}{' '}
                                                        {description && (
                                                            <ChevronDown
                                                                style={{
                                                                    transform: `rotate(${open ? '180deg' : '0'})`,
                                                                    transition: 'transform .3s',
                                                                }}
                                                            />
                                                        )}
                                                    </h3>
                                                    <Accordion open={open}>
                                                        <p className='h-pb-1'>{description}</p>
                                                    </Accordion>
                                                </>
                                            )}
                                        </Toggler>
                                        {answers.map(({ answer, id: answerId }) => (
                                            <RadioInput
                                                key={answerId}
                                                color={(['yellow', 'pink', 'blue', 'purple'] as const)[index % 4]}
                                                id={`aswer-${id}-${answerId}`}
                                                isActive={value[id] === answerId}
                                                value={answerId.toString()}
                                                name='radio'
                                                onChange={(val) =>
                                                    setValue((currValue) => ({ ...currValue, [id]: +val }))
                                                }
                                                disabled={false}
                                            >
                                                {answer}
                                            </RadioInput>
                                        ))}
                                    </div>
                                );
                            })}
                            <div className='h-flex h-text-right h-mt-3'>
                                {/* {!!Object.keys(value).length && (
                                    <Button color="gray" size="link" onClick={() => setValue({})} className="h-mr-2">
                                        Reset
                                    </Button>
                                )} */}
                                <Button onClick={submit} disabled={Object.entries(value).length !== questions?.length}>
                                    Potvrdit výběr
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal isOpen={datePickModal} close={() => setDatePickModal(false)}>
                <div className='h-pt-2 h-pb-65'>
                    <h3>Zvolte počáteční den</h3>
                    <p>Vyberte si, kterým dnem v týdnu budeš chtít tréninkový plán začínat.</p>
                    <div onClick={() => {
                        if (dateInputRef && !isSafari()) {
                            try {
                                // @ts-ignore
                                dateInputRef.current!.showPicker();
                                dateInputRef.current!.focus();
                                dateInputRef.current!.click();
                            } catch (error) {}


                        }
                    }} className='h-text-left h-mx-auto' style={{ maxWidth: '300px' }}>
                        <input ref={dateInputRef} className='h-datepicker-input' name='date' type='date'
                               min={new Date().toISOString().split('T')[0]} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                    </div>
                    <Button type='button' loading={false} className='h-mt-2' disabled={startDate === null}
                            onClick={() => {
                                if (profile?.fitness_plan_id) {
                                    setDatePickModal(false);
                                    setChangeModal(true);
                                } else {
                                    setDatePickModal(false);
                                    selectPlan();
                                }
                            }}>
                        Potvrdit výběr
                    </Button>
                </div>
            </Modal>
            <Modal isOpen={changeModal} close={() => setChangeModal(false)}>
                <div>
                    <h3 className='h-mb-15'>Provádíte změnu</h3>
                    <p className='h-mb-225'>
                        Když zvolíš tento program, <strong>resetuje se ti aktuální program</strong> a nebudeš v něm moct
                        pokračovat.
                    </p>
                    <div className='h-text-center'>
                        <Button
                            color='black'
                            className='h-m-05'
                            to={routesURLs.fitnessPrograms}
                            onClick={() => setChangeModal(false)}
                        >
                            Zpět
                        </Button>
                        <Button
                            onClick={() => {
                                setDatePickModal(false);
                                setChangeModal(false);
                                setExtraWarningModal(true);
                            }}
                            className='h-m-05'
                            color='warning'
                        >
                            Zvolit tento program
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={extraWarningModal} close={() => setExtraWarningModal(false)}>
                <div>
                    <WarningCircle />
                    <h3 className='h-my-25'>
                        Seš si opravdu jistá, že chceš
                        <br />
                        resetovat původní
                        <br />
                        program?
                    </h3>
                    <div className='fitness-programs-confirm'>
                        <Button
                            onClick={() => {
                                setExtraWarningModal(false);
                                selectPlan();
                            }}
                            color='warning'
                        >
                            Ano, změnit
                        </Button>
                        <Button to={routesURLs.fitnessPrograms} onClick={() => setChangeModal(false)} color='black'>
                            Ne, zachovat původní
                        </Button>
                    </div>
                </div>
            </Modal>
            {loadingModal && (
                <Modal isOpen={loadingModal} close={() => {
                }}>
                    <div className='h-py-2'>
                        <h3 className='h-mb-15'>Sestavujeme ti program</h3>
                        <div className='h-py-3 h-text-center'>
                            <span className='loader-spinner' />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default FitnessProgramForm;
