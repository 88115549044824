import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import RecipeItem from '../../../components/Recipe/RecipeItem/RecipeItem';
import Button from '../../../components/UI/Button/Button';
import useWeekMeals from '../../../hooks/useWeekMeals';
import api from '../../../services/api';

const DayMenuModal: React.FC<{
    close: () => void;
    isOpen: boolean;
}> = ({ close, isOpen }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const type = new URLSearchParams(location.search).get('type');
    const day = new URLSearchParams(location.search).get('day');
    const id = new URLSearchParams(location.search).get('id');

    const { data: meals, mutate } = useWeekMeals();

    const selectRecipe = (id: number) => {
        if (!type || !day) return;
        setLoading(true);
        api.post(`/diet-plan/diet/recipes/${id}/set`, { type, day })
            .then(async () => {
                await mutate();
                document.body.style.overflow = 'visible';
                close();
            })
            .finally(() => setLoading(false));
    };

    if (!type || !day) return null;

    return (
        <Modal close={close} isOpen={isOpen} className="h-px-sm-1">
            <h3 className="h-mb-1">Změna receptu</h3>
            <p className="h-mb-15">Vyber nový recept, který vyměníme za původní.</p>
            <div>
                {meals?.favorite_recipes
                    .filter((recipe) => recipe.type.toString() === type)
                    .filter((recipe)=>recipe.id.toString() !== id)
                    .map((recipe) => (
                        <RecipeItem
                            key={recipe.id}
                            onClick={selectRecipe}
                            recipe={recipe}
                            loading={loading}
                            favorite={true}
                        />
                    ))}
                {meals?.recipes
                    .filter((recipe) => recipe.type.toString() === type)
                    .filter((recipe)=>recipe.id.toString() !== id)
                    .filter(
                        (_, index) =>
                            index <
                            15 -
                                (meals?.favorite_recipes.filter((recipe) => recipe.type.toString() === type).length ??
                                    0),
                    )
                    .map((recipe) => (
                        <RecipeItem
                            key={recipe.id}
                            onClick={selectRecipe}
                            recipe={recipe}
                            loading={loading}
                            favorite={false}
                        />
                    ))}
            </div>
            <Button
                onClick={() => {
                    document.body.style.overflow = 'visible';
                    close();
                }}
                size="link"
            >
                Zavřít
            </Button>
        </Modal>
    );
};

export default DayMenuModal;
