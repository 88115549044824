import React, { useEffect, useMemo, useRef, useState } from 'react';
import './VideoComponent.scss';
import ReactPlayer from 'react-player';
import { classes, compareArrays, createArray, plural } from '../../services/utils';
import ExerciseBreak from './ExerciseBreak/ExerciseBreak';
import ExercisePreview from './ExercisePreview/ExercisePreview';
import { ExerciseType, TrainingType } from '../../services/types';
import Tagline from '../UI/Tagline/Tagline';
import useViewport from '../../hooks/useViewport';
import CircularProgress from '../UI/CircularProgress/CircularProgress';
import Button from '../UI/Button/Button';
import screenfull from 'screenfull';
import { FitnessWeek, getFitnessWeeks, updateTraining } from '../../moduls/trainings';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import useSWR from 'swr';
import { longBeep } from '../../constants/audio';

interface VideoComponentPropsType {
    trainingId: number;
    open: boolean;
    closeVideo: () => void;
    training: TrainingType;
    color: string;
    videoId: number | null;
    loop?: boolean;
}

interface VideoDataType {
    type: 'init' | 'preview' | 'superserie_pause' | 'video' | 'pause' | 'end';
    exercise: number;
    pause?: number;
    series?: number;
    current_serie?: number;
    phaseType?: 0 | 1 | 2;
}

const audios: Record<number, HTMLAudioElement> = {};

const VideoComponent: React.FC<VideoComponentPropsType> = ({
    trainingId,
    open,
    closeVideo,
    training,
    color,
    loop = false,
    videoId,
}) => {
    const { breakpoint } = useViewport();
    const history = useHistory();
    const [index, setIndex] = useState(0);
    const [flash, setFlash] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [buffering, setBuffering] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [audioMuted, setAudioMuted] = useState(true);
    const video = useRef<ReactPlayer>(null);
    const userAgent = window.navigator.userAgent;
    const isIOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
    const [key, setKey] = useState(0);
    const [opacity, setOpacity] = useState(0);

    if (!audios[trainingId]) {
        audios[trainingId] = new Audio(training.music);
    }

    const audio: HTMLAudioElement = audios[trainingId];
    const [exercises, setExercises] = useState<Record<number, ExerciseType>>({});
    const [data, setData] = useState<VideoDataType[]>([]);

    const { mutate: mutateFitnessWeeks } = useSWR<FitnessWeek[]>('fitness-plan', getFitnessWeeks, {
        revalidateOnFocus: false,
    });

    useEffect(() => {
        audio.muted = true;

        function closeVideoModal(event: KeyboardEvent) {
            if (event.key === 'Escape') closeVideo();
        }
        window.addEventListener('keydown', closeVideoModal, false);
        document.addEventListener('fullscreenchange', onFullscreenClose, false);
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenClose, false);
            window.removeEventListener('keydown', closeVideoModal, false);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // set exervices into object
        const seriesWithIds = training.series.map((serie) => serie.exercises.map((item) => item.exercise_id));
        const exerciseTemp: Record<number, ExerciseType> = {};
        training.exercises.forEach((exercise) => {
            exerciseTemp[exercise.id] = exercise;
        });
        let superSerieTempCurrent = 0;
        let superSerieTempIndex = 0;
        setExercises(exerciseTemp);

        const getSuperSerieTotal = (superSerie: number[]) => {
            return seriesWithIds.filter((serie) => {
                return compareArrays(serie, superSerie);
            }).length;
        };

        const getSuperSerieCurrent = (superSerie: number[], index: number) => {
            if (compareArrays(superSerie, seriesWithIds[index - 1] ?? [])) {
                if (superSerieTempIndex !== index) {
                    superSerieTempCurrent++;
                }
                superSerieTempIndex = index;
                return superSerieTempCurrent;
            }
            superSerieTempCurrent = 0;
            return 0;
        };

        // create video data
        const dataset: any[] = [];
        dataset.push({ type: 'init' });
        if (loop) {
            dataset.push({ type: 'video', exercise: videoId });
        } else {
            training.series.forEach((serie, seriesIndex) => {
                serie.exercises.forEach((item, index) => {
                    if (index === 0) {
                        dataset.push({ type: 'preview', exercise: item.exercise_id, phaseType: serie.type });
                    }
                    if (index > 0) {
                        dataset.push({ type: 'superserie_pause', exercise: item.exercise_id, phaseType: serie.type });
                    }
                    createArray(item.series).forEach((current) => {
                        dataset.push({
                            type: 'video',
                            phaseType: serie.type,
                            exercise: item.exercise_id,
                            series:
                                serie.exercises.length > 1
                                    ? getSuperSerieTotal(serie.exercises.map((exercise) => exercise.exercise_id))
                                    : item.series,
                            current_serie:
                                serie.exercises.length > 1
                                    ? getSuperSerieCurrent(
                                          serie.exercises.map((exercise) => exercise.exercise_id),
                                          seriesIndex,
                                      )
                                    : current,
                        });
                        if (
                            !(
                                // no pause at last serie of last exercise
                                (
                                    seriesIndex === training.series.length - 1 &&
                                    index === serie.exercises.length - 1 &&
                                    current === item.series - 1
                                )
                            ) &&
                            (item.series > 1 || (item.series === 1 && index + 1 >= serie.exercises.length))
                        ) {
                            dataset.push({
                                type: 'pause',
                                exercise: item.exercise_id,
                                pause: exerciseTemp[item.exercise_id].pause,
                                phaseType: serie.type,
                            });
                        }
                    });
                });
            });
            dataset.push({ type: 'end' });
        }
        setData(dataset);

        // eslint-disable-next-line
    }, [training, videoId]);

    useEffect(() => {
        setPlaying(false);
        setAudioMuted(true);
        audio.muted = true;
        if (open) {
            reset();
        } else {
            if (screenfull.isEnabled) {
                screenfull.exit();
            }
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (!loop) {
            audio.loop = true;
            playing ? audio.play() : audio.pause();
        } else {
            toggleAudio();
        }
        setVideoPlaying(playing);
        // eslint-disable-next-line
    }, [playing]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (open && isIOS) {
            timeout = setTimeout(() => {
                setKey((prev) => prev + 1);
                setOpacity(1);
            }, 100);
        }

        return () => {
            clearTimeout(timeout);
        };

        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        setFlash(true);
        setTimeout(() => {
            setFlash(false);
        }, 200);
    }, [index]);

    const reset = () => {
        setIndex(0);
        setPlayed(0);
        setFlash(false);
        setPlaying(false);
        if (video.current) video.current.seekTo(0);
        audio.currentTime = 0;
    };

    const onEnded = () => {
        setIndex((currentIndex) => currentIndex + 1);
        setVideoPlaying(false);
        setPlayed(0);
        longBeep.currentTime = 0;
        longBeep.play();
    };

    const onFullscreenClose = () => {
        if (!document.fullscreenElement) {
            setFullscreen(false);
        }
    };

    const onBreakEnded = () => {
        if (video.current) video.current.seekTo(0);
        setIndex((currentIndex) => currentIndex + 1);
        setVideoPlaying(true);
    };
    const onPreviewEnded = () => {
        setIndex((currentIndex) => currentIndex + 1);
        if (video.current) video.current.seekTo(0);
        setVideoPlaying(true);
    };
    const onPreviewStart = () => {
        setVideoPlaying(true);
    };

    const toggleFullscreen = (open: boolean) => {
        setFullscreen(open);
        if (screenfull.isEnabled) {
            if (open) {
                screenfull.request(undefined, { navigationUI: 'hide' });
            } else {
                screenfull.exit();
            }
        }
    };

    const togglePlay = () => {
        if (index === 0) setIndex((currentIndex) => currentIndex + 1);
        setPlaying(!playing);
        // audio.muted = true;
    };

    const toggleAudio = () => {
        if (audioMuted && audio.paused) {
            audio.play();
        }

        setAudioMuted((muted) => {
            audio.muted = !muted;
            return !muted;
        });
    };

    const changeVideoStep = (forward: boolean) => {
        if (video.current) video.current.seekTo(0);
        setIndex((currentIndex) => currentIndex + (forward ? 1 : -1));
        if (data[index].type === 'video') setVideoPlaying(true);
    };

    const finishedVideoClick = () => {
        if (fullscreen) toggleFullscreen(false);
        closeVideo();
        updateTraining(trainingId, { done_at: format(new Date(), 'yyyy-MM-dd') }).then(async (res) => {
            const mutatedData = await mutateFitnessWeeks();
            if (!mutatedData) return;
            if (res.status === 200 && res.data.message === 'Fitness plan completed.') {
                history.push(routesURLs.fitnessPlan + '?finish-modal=1');
            } else {
                history.push(routesURLs.fitnessPlan);
            }
        });
    };

    const handleSeekMouseDown = () => {
        setSeeking(true);
    };

    const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = (e: any) => {
        setSeeking(false);
        if (video.current) video.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = (state: {
        played: number;
        playedSeconds: number;
        loaded: number;
        loadedSeconds: number;
    }) => {
        if (!seeking) {
            setPlayed(state.played);
        }
    };

    const player = useMemo(() => {
        return !data.length || !Object.keys(exercises).length ? null : (
            <ReactPlayer
                className="video"
                url={
                    loop
                        ? exercises[videoId as number].video_mobile_url!
                        : exercises[data[index].exercise]?.video_url ?? training.exercises[0].video_url!
                }
                ref={video}
                muted={false}
                loop={loop}
                controls={false}
                playing={videoPlaying}
                width={loop ? 'auto' : '100%'}
                height={'100%'}
                key={key}
                style={{ opacity: isIOS ? opacity : 1 }}
                onEnded={loop ? () => {} : onEnded}
                onProgress={handleProgress}
                onBuffer={() => {
                    setBuffering(true);
                }}
                onBufferEnd={() => {
                    setBuffering(false);
                }}
                playsinline
            />
        );
    }, [exercises, loop, data, index, videoId, training.exercises, videoPlaying, key, opacity, isIOS]);

    const tagline = useMemo(() => {
        return (
            <Tagline size="small" className="h-color-medium-gray">
                celkem {training.exercises.length} {plural(training.exercises.length, 'cvik', 'cviky', 'cviků')}
            </Tagline>
        );
    }, []);

    const finishLine = useMemo(() => {
        return data[index] ? (
            <div
                className={classes('video-overlay is-centered', {
                    'is-open': data[index].type === 'end' && open,
                })}
            >
                <h3>Odvedla jsi skvělou práci! Těšíme se na tebe u dalšího tréninku.</h3>
                <Tagline size="small" className="h-color-medium-gray h-mb-2">
                    Tvoje Strong beauty koučky
                </Tagline>
                <Button onClick={finishedVideoClick}>Mám odcvičeno</Button>
            </div>
        ) : null;
    }, [data[index], open]);

    if (!data.length || !Object.keys(exercises).length) return null;

    return (
        <div
            className={classes('video-container', {
                'is-open': open,
                'is-fullscreen': fullscreen,
            })}
        >
            <div onClick={closeVideo} className="video-backdrop"></div>
            <button onClick={closeVideo} className="video-close">
                Zavřít
            </button>
            <div
                className={classes(`video-wrapper is-${color}`, {
                    'is-flash': flash,
                    'is-vertical': loop,
                    'is-sequence': !videoId,
                })}
            >
                {player}
                <ReactPlayer
                    className="video"
                    url={exercises[data[index + 1]?.exercise]?.video_url ?? training.exercises[0].video_url!}
                    muted={true}
                    controls={false}
                    playsinline
                    playing={true}
                    style={{ position: 'absolute', top: '-9999px', visibility: 'hidden', pointerEvents: 'none' }}
                />

                {!loop && (
                    <>
                        {data[index].type === 'video' && (
                            <div className="video-seeker-wrapper">
                                <input
                                    type="range"
                                    min={0}
                                    max={0.999999}
                                    className="video-seeker"
                                    step="any"
                                    value={played}
                                    onMouseDown={handleSeekMouseDown}
                                    onChange={handleSeekChange}
                                    onMouseUp={handleSeekMouseUp}
                                    onTouchEnd={handleSeekMouseUp}
                                />
                                <div className="video-seeker-track">
                                    <span
                                        className="video-seeker-track-played"
                                        style={{ width: `${played * 100}%` }}
                                    ></span>
                                </div>
                            </div>
                        )}
                        {data[index].type === 'video' && (
                            <>
                                <div className="video-notes-background" />
                                <div className="video-notes">
                                    {!!data[index].series && (
                                        <div className="video-notes-sets">{`${data[index].current_serie! + 1} / ${
                                            data[index].series
                                        }`}</div>
                                    )}
                                    <h2 className="video-notes-title">
                                        {exercises[data[index]?.exercise].repetitions &&
                                        !exercises[data[index]?.exercise].duration
                                            ? exercises[data[index].exercise].repetitions + 'x '
                                            : ''}
                                        {exercises[data[index]?.exercise].name}
                                    </h2>
                                </div>
                                {exercises[data[index]?.exercise].duration && (
                                    <div className="video-notes-progress">
                                        <CircularProgress
                                            total={video?.current?.getDuration() || 0}
                                            value={
                                                Number(
                                                    (
                                                        (video?.current?.getDuration() ?? 0) -
                                                        (video?.current?.getCurrentTime() ?? 0)
                                                    ).toFixed(0),
                                                ) || 0
                                            }
                                            endText="END"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                <div className="video-skip-button">
                    {data[index].phaseType === 1 && (
                        <button onClick={() => setIndex(data.map((item) => item.phaseType).indexOf(0))}>
                            Přeskočit zahřátí
                        </button>
                    )}
                    {data[index].phaseType === 2 && (
                        <button onClick={() => setIndex(data.length - 1)}>Přeskočit strečink</button>
                    )}
                </div>
                {index > 0 && (
                    <div className="video-navigation-controls">
                        {!loop && (
                            <button
                                onClick={toggleAudio}
                                className={classes('video-audio-button', {
                                    'is-muted': audioMuted,
                                })}
                            >
                                Mute music
                            </button>
                        )}
                        <button
                            onClick={() => toggleFullscreen(!fullscreen)}
                            className={classes('video-fullscreen-button', {
                                'is-fullscreen': fullscreen,
                            })}
                        >
                            Fullscreen
                        </button>
                        {!loop && (
                            <>
                                <button
                                    onClick={() => changeVideoStep(false)}
                                    className="video-arrow is-prev"
                                    title="Vrátit klip"
                                    disabled={index <= 1}
                                >
                                    Prev
                                </button>
                                <button
                                    onClick={() => changeVideoStep(true)}
                                    className="video-arrow is-next"
                                    title="Přeskočit klip"
                                    disabled={index >= data.length - 1}
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                )}
                {data[index].type === 'pause' && (
                    <ExerciseBreak length={data[index].pause!} onEnded={onBreakEnded} playing={playing} />
                )}
                {data[index].type === 'preview' && (
                    <ExercisePreview
                        title="Připrav se"
                        length={10}
                        onStart={onPreviewStart}
                        onEnded={onPreviewEnded}
                        playing={playing}
                        name={exercises[data[index]?.exercise].name}
                    />
                )}
                {data[index].type === 'superserie_pause' && (
                    <ExercisePreview
                        title="Přejdi na další cvik v supersérii."
                        length={10}
                        small
                        onStart={onPreviewStart}
                        onEnded={onPreviewEnded}
                        playing={playing}
                        name={exercises[data[index]?.exercise].name}
                    />
                )}

                {!loop && (
                    <>
                        <div
                            className={classes('video-overlay', {
                                'is-open': data[index].type === 'init' && open,
                            })}
                        >
                            <h3>V tomhle tréninku tě čeká</h3>
                            {tagline}
                            {breakpoint !== 'small' && (
                                <div
                                    className="video-overlay-items-wrapper container-row middle-md center-md h-mt-2"
                                    style={{ maxWidth: 'none' }}
                                >
                                    {training.series
                                        .filter((serie) => serie.type === 0)
                                        .map((serie) => serie.exercises)
                                        .flat(1)
                                        .map((item) => exercises?.[item.exercise_id])
                                        .filter(
                                            (exercise, index, self) =>
                                                index === self.findIndex((ex) => ex.id === exercise.id),
                                        )
                                        .map((exercise, index) => {
                                            if (index < 6) {
                                                return (
                                                    <div key={index} className="col-md-4">
                                                        <div
                                                            className="video-overlay-item"
                                                            title={exercise.name}
                                                            style={{
                                                                backgroundImage: `url('${exercise.image}')`,
                                                            }}
                                                        ></div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                </div>
                            )}
                        </div>
                        {finishLine}
                    </>
                )}
                {data[index].type !== 'end' && (
                    <button
                        onClick={togglePlay}
                        className={classes('video-play-button', {
                            'is-playing': playing,
                        })}
                    >
                        Přehrát video
                    </button>
                )}

                {buffering && (
                    <div className="loader h-py-0">
                        <div className="loader-spinner"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoComponent;
