import { Formik, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import FormInputText from '../../components/Form/Inputs/FormInputText/FormInputText';
import BackButton from '../../components/UI/Button/BackButton';
import Button from '../../components/UI/Button/Button';
import { routesURLs } from '../../Routes';
import './ProfileEdit.scss';
import { ProfileEditFormTypes } from '../../components/Form/form-types';
import FakePasswordInput from './FakePasswordInput/FakePasswordInput';
import { RouteComponentProps } from 'react-router-dom';
import { editProfile } from '../../moduls/profile';
import { transformErrors } from '../../services/utils';
import useProfile from '../../hooks/useProfile';
import { ProfileDataType } from '../../services/types';
import useNotification from '../../hooks/useNotification';

const attributes = {
    name: {
        placeholder: 'Zadejte uživatelské jméno',
        label: 'Uživatelské jméno',
    },
    email: {
        placeholder: 'Zadejte e-mail',
        label: 'E-mail',
    },
    password: {
        placeholder: 'Zadejte heslo',
        label: 'Nové heslo',
        password: true,
    },
    password_confirmation: {
        placeholder: 'Zadejte heslo znovu',
        label: 'Heslo znovu',
        password: true,
    },
};

const ProfileEdit = (props: RouteComponentProps) => {
    const [editPassword, setEditPassword] = useState(false);

    const { data: profile, mutate } = useProfile();
    const { open: openNotification } = useNotification();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Povinné pole'),
        email: Yup.string().email('Nevalidní e-mail').required('Povinné pole'),
        // eslint-disable-next-line
        password: Yup.string().min(8, 'Minimální délka je ${min} znaků'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), undefined], 'Hesla se musí shodovat'),
    });

    const onSubmit = async (values: ProfileEditFormTypes, { setErrors }: FormikHelpers<ProfileEditFormTypes>) => {
        if (profile?.is_testing) {
            openNotification('Údaje testovacího účtu nelze měnit.', 'error');
            return;
        }

        if (!editPassword || (!values.password && !values.password_confirmation)) {
            delete values.password;
            delete values.password_confirmation;
        }

        editProfile(values)
            .then(() => {
                mutate(
                    {
                        ...(profile as ProfileDataType),
                        name: values.name,
                        email: values.email,
                    },
                    false,
                );
                props.history.push(routesURLs.profile);
            })
            .catch((error) => setErrors(transformErrors(error)));
    };

    const initialValues = {
        name: profile!.name,
        email: profile!.email,
        password: '',
        password_confirmation: '',
    };

    return (
        <div className="profile-edit page-row container-row center-md">
            <div className="col-lg-10 h-mb-2">
                <BackButton to={routesURLs.profile}>Zpět</BackButton>
            </div>
            <div className="col-lg-6 col-md-8">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                >
                    {({ isSubmitting, resetForm, dirty }) => {
                        const resetEditing = () => {
                            resetForm();
                            setEditPassword(false);
                        };
                        return (
                            <Form className="profile-edit-form">
                                <FormInputText name="name" attributes={attributes.name} />
                                <FormInputText name="email" attributes={attributes.email} />
                                {!editPassword && <FakePasswordInput onClick={() => setEditPassword(true)} />}
                                {editPassword && (
                                    <>
                                        <FormInputText name="password" attributes={attributes.password} />
                                        <FormInputText
                                            name="password_confirmation"
                                            attributes={attributes.password_confirmation}
                                        />
                                    </>
                                )}
                                <div className="h-text-right h-mt-15">
                                    {dirty && (
                                        <Button onClick={resetEditing} size="link" className="h-mr-15 h-mr-sm-1">
                                            Zrušit změny
                                        </Button>
                                    )}
                                    <Button type="submit" loading={isSubmitting}>
                                        Uložit změny
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ProfileEdit;
