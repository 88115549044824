import { TrainingDataType, TrainingKeysType } from './types';
import domiTrainer from '../assets/images/backgrounds/domi.png';
import katyTrainer from '../assets/images/backgrounds/katy.png';
import martiTrainer from '../assets/images/backgrounds/marti.png';
import reniTrainer from '../assets/images/backgrounds/reni.png';
import ivetTrainer from '../assets/images/backgrounds/ivet.png';

export const getBreakpoint = () => {
    if (window.innerWidth >= 1024) {
        return 'large';
    } else {
        return 'small';
    }
};

export const classes = (classString: string | (string | undefined)[], classes?: { [key: string]: boolean }) => {
    const classesList = [];
    for (const classKey in classes) {
        if (classes.hasOwnProperty(classKey)) {
            const condition = classes[classKey];
            if (condition) {
                classesList.push(classKey);
            }
        }
    }
    return (
        `${typeof classString === 'string' ? classString : classString.filter((classItem) => !!classItem).join(' ')}${
            classesList.length && classString ? ' ' : ''
        }${classesList.join(' ')}` || undefined
    );
};

type ErrorType = { response: { data: { errors: { [param: string]: string[] }; msg: string } } };

export const transformErrors = (error: ErrorType) => {
    const data = error.response.data.errors;
    const errorList = Object.keys(data);
    const errors: { [key: string]: string } = {};
    if (errorList.length) {
        errorList.forEach((item) => {
            errors[item] = data[item][0];
        });
    }
    return errors;
};

export const pad = function (a: number) {
    return a < 10 ? '0' + a : a;
};

export const formateNumber = (number: number, round: number) => {
    let decimals = Math.pow(10, round);
    const adjNumber = Math.round(number * decimals) / decimals;
    return adjNumber
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const sessionStore = {
    get: (item: string) => {
        return JSON.parse(sessionStorage.getItem(item) || JSON.stringify(''));
    },
    set: (item: string, values: any) => {
        sessionStorage.setItem(item, JSON.stringify(values));
    },
};

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift();
};

export const setCookie = (name: string, value: string, days: number) => {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = `${name}=${value};${days ? expires : ''}; path=/`;
};

export const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const plural = (count: number, singular: string, plural: string, plural5more: string) => {
    if (count === 1) {
        return singular;
    } else if (count > 1 && count < 5) {
        return plural;
    } else {
        return plural5more;
    }
};

export const createArray = (arrayLength: number) => {
    const newArray = [];
    for (let i = 0; i < arrayLength; i++) {
        newArray.push(i);
    }
    return newArray;
};

export const compareArrays = (firstArray: any[], secondArray: any[]) => {
    return firstArray.every((item) => secondArray.indexOf(item) > -1 && firstArray.length === secondArray.length);
};

export const setNoScroll = (condition: boolean) => {
    document.body.style.overflow = condition ? 'hidden' : 'visible';
};

export function groupTrainings(data?: TrainingDataType) {
    if (data) {
        const result: TrainingKeysType[] = [
            'training_1',
            'training_2',
            'training_3',
            'training_belly',
            'training_cardio_hiit',
            'training_cardio_liss',
        ];
        if (data.frequency === 'FOUR_TIMES') {
            result.splice(3, 0, 'training_4');
        }
        return result.map((key) => ({
            key,
            color: trainingInfo[key].color,
            backgroundText: trainingInfo[key].backgroundText,
            icon: trainingInfo[key].icon,
            ...data[key],
        }));
    }
    return [];
}

export const trainerImage = {
    'Katy Blažčíková': katyTrainer,
    'Domi Hostinská': domiTrainer,
    'Marti Vlášek': martiTrainer,
    'Reni Markowiczová': reniTrainer,
    'Iveta Mentl': ivetTrainer,
};

export const trainingInfo = {
    training_1: {
        color: 'yellow',
        backgroundText: '01',
        icon: 'dumbbells-black',
    },
    training_2: {
        color: 'pink',
        backgroundText: '02',
        icon: 'dumbbells-black',
    },
    training_3: {
        color: 'blue',
        backgroundText: '03',
        icon: 'dumbbells-black',
    },
    training_4: {
        color: 'purple',
        backgroundText: '04',
        icon: 'dumbbells-black',
    },
    training_belly: {
        color: 'black',
        backgroundText: 'ABS',
        icon: 'abs-black',
    },
    training_cardio_hiit: {
        color: 'gray',
        backgroundText: 'HIIT',
        icon: 'timer-black',
    },
    training_cardio_liss: {
        color: 'gray',
        backgroundText: 'LISS',
        icon: 'running-machine-black',
    },
};


export function isSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes("safari") && !userAgent.includes("chrome");
};
  
 