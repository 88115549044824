import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { mutate } from 'swr';
import { mutateCallback } from 'swr/dist/types';

import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { useHistory } from 'react-router-dom';
import useNotification from '../../../hooks/useNotification';
import { FitnessWeekNew, replanTrainingFuture } from '../../../moduls/trainings';
import { routesURLs } from '../../../Routes';

interface Props {
    isOpen: boolean;
    moveFrom?: string;
    setClose: () => void;
    mutateFitnessWeeks: (
        data?: FitnessWeekNew[] | Promise<FitnessWeekNew[]> | mutateCallback<FitnessWeekNew[]> | undefined,
        shouldRevalidate?: boolean,
    ) => Promise<FitnessWeekNew[] | undefined>;
}

const MissedManyTrainingsModal = (props: Props) => {
    const history = useHistory();
    const { open: openNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    let timeout: NodeJS.Timeout;

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    return (
        <Modal isOpen={props.isOpen} close={() => {}} hideCloseButton>
            <h3 className="h-mb-3">Už nějakou dobu jsi necvičila. Je čas se zase pustit do toho!</h3>
            <div className="btn-column">
                <Button
                    size="small"
                    loading={isLoading}
                    color="gray"
                    onClick={() => {
                        setIsLoading(true);
                        replanTrainingFuture({
                            move_from: props.moveFrom ?? format(new Date(), 'yyyy-MM-dd'),
                            to_date: format(new Date(), 'yyyy-MM-dd'),
                        })
                            .then(async (res) => {
                                await mutate('fitness-plan');
                                await props.mutateFitnessWeeks();
                                props.setClose();
                            })
                            .catch(() => {
                                openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                            });
                    }}
                >
                    Přesunout tréninky ode dneška
                </Button>
                <Button
                    size="small"
                    color="gray"
                    loading={isLoading}
                    onClick={() => {
                        props.setClose();
                        timeout = setTimeout(() => {
                            history.push(routesURLs.fitnessPrograms);
                        }, 100);
                    }}
                >
                    Vyber si nový fitness program
                </Button>
            </div>
        </Modal>
    );
};

export default MissedManyTrainingsModal;
