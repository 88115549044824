import React from 'react';
import RadioInput from '../../../components/Form/Inputs/RadioInput/RadioInput';
import gymImage from '../../../assets/images/icons/app/gym-blue.svg';
import homeGymImage from '../../../assets/images/icons/app/home-gym-blue.svg';
import combineImage from '../../../assets/images/icons/app/combine.svg';
import { ProfileSetupValues } from '../../../services/types';

interface ProfileSetupPlacePropsType {
    place: string;
    setPlace: (value: string) => void;
}

export const setup03OptionsPlace = [
    {
        image: gymImage,
        text: ProfileSetupValues.FITNESS,
        value: '1',
    },
    {
        image: homeGymImage,
        text: ProfileSetupValues.HOME,
        value: '0',
    }, 
    {
        image: combineImage,
        text: ProfileSetupValues.COMBINE,
        value: '2',
    },
];

const ProfileSetupPlace: React.FC<ProfileSetupPlacePropsType> = ({ place, setPlace }) => {
    return (
        <>
            <h1>Kde cvičíte?</h1>
            <div>
                {setup03OptionsPlace.map((option) => (
                    <RadioInput
                        key={option.value}
                        color="blue"
                        id={option.value}
                        image={option.image}
                        isActive={place === option.value}
                        value={option.value}
                        name="radio"
                        onChange={setPlace}
                    >
                        {option.text}
                    </RadioInput>
                ))}
            </div>
        </>
    );
};

export default ProfileSetupPlace;
