import React from 'react';
import useSWR, { cache } from 'swr';
import RecipeListItem from '../../components/Recipe/RecipeListItem/RecipeListItem';
import BackButton from '../../components/UI/Button/BackButton';
import Loader from '../../components/UI/Loader/Loader';
import Tagline from '../../components/UI/Tagline/Tagline';
import { getShoppingList } from '../../moduls/mealPlan';
import { routesURLs } from '../../Routes';
import { ShoppingListType } from '../../services/types';
import './MealPlanShoppingList.scss';

const MealPlanShoppingList = () => {
    const { data } = useSWR<ShoppingListType>('shopping-list', getShoppingList, {
        revalidateOnMount: true,
    });

    if (data === undefined) return <Loader />;

    return (
        <div className="shopping-list page-row container">
            <div className="shopping-list-header">
                <BackButton to={routesURLs.mealPlanMenu} />
                <Tagline size="small" className="h-color-medium-gray">
                    Nákupní seznam
                </Tagline>
                <div className="shopping-list-header-controls">
                    {/* <a
                        href={data.shopping_list_file!}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button is-gray is-small"
                    >
                        Stáhnout nákupní seznam
                        <i className="icon-download-white" />
                    </a> */}
                </div>
            </div>
            <div className="recipe-list">
                <ShoppingLIstItem title="Bílkovinové potraviny" icon="meat-pink" data={data.proteins} />
                <ShoppingLIstItem title="Sacharidové potraviny" icon="carbs-yellow" data={data.carbohydrates} />
                <ShoppingLIstItem title="Zdravé tuky" icon="fat-blue" data={data.healthy_fats} />
                <ShoppingLIstItem title="Ovoce" icon="fruit-yellow" data={data.fruit} />
                <ShoppingLIstItem title="Zelenina" icon="vegetable-pink" data={data.vegetables} />
                <ShoppingLIstItem title="Luštěniny" icon="legumes-purple" data={data.legumes} />
                <ShoppingLIstItem
                    title="Rostlinná mléka a mléčné výrobky"
                    icon="milk-blue"
                    data={data.dairy_products}
                />
                <ShoppingLIstItem title="Ochucovadla" icon="drinks-purple" data={data.condiment} />
                <ShoppingLIstItem title="Koření" icon="legumes-purple" data={data.dry_spices} />
            </div>
        </div>
    );
};

export default MealPlanShoppingList;

const ShoppingLIstItem: React.FC<{ title: string; icon: string; data: ShoppingListType[keyof ShoppingListType] }> = ({
    title,
    icon,
    data,
}) => {
    return (
        <RecipeListItem title={title} icon={icon}>
            {data!.map((item, index) => (
                <tr key={item.name + item.amount + index}>
                    <th>{item.name}</th>
                    <th>{item.amount}</th>
                </tr>
            ))}
        </RecipeListItem>
    );
};
