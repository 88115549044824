import React, { useState } from 'react';
import useFitnessPrograms, { FitnessProgram } from '../../hooks/useFitnessPrograms';
import useProfile from '../../hooks/useProfile';
import { classes } from '../../services/utils';
import { ReactComponent as Chevron } from '../../assets/images/icons/ic-chevron-right.svg';
import './FitnessPrograms.scss';
import Modal from '../../components/Modal/Modal';
import { Link } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import useFitnessPlan from '../../hooks/useFitnessPlan';
import { apiErrors } from '../../services/api';

const FitnessPrograms = () => {
    const { data, isValidating } = useFitnessPrograms();
    const { data: profile } = useProfile();
    const { data: fitnessPlan, error: fitnessPlanError } = useFitnessPlan(
        profile?.fitness_plan_id as number | undefined,
    );

    return (
        <div className="fitness-programs dashboard-grid page-row container">
            <div className="dashboard-side-text">
                <div className="overview-text">
                    <h1>Fitness programy</h1>

                    {fitnessPlan?.fitness_program ? (
                        <FitnessProgramItem
                            key={fitnessPlan?.fitness_program.id}
                            {...fitnessPlan?.fitness_program}
                            isCurrent={true}
                            color="yellow"
                            titleComponent={
                                <>
                                    <h5 className="h-mb-0 h-text-regular">Aktuální fitness program:</h5>
                                    <div className="h-mb-0">{fitnessPlan?.fitness_program.name}</div>
                                </>
                            }
                            className="h-mb-2"
                        />
                    ) : (
                        <p>
                            Vyber si program, který bude nejlépe sedět tvým cílům a tomu, co tě baví! Pokud si nejsi
                            jistá, udělej si kvíz.
                        </p>
                    )}
                </div>
            </div>
            <div className="dashboard-content">
                {fitnessPlan?.fitness_program ? (
                    <h4 className="h-mb-2">Další programy:</h4>
                ) : (
                    <div className="h-mb-2" />
                )}

                {fitnessPlanError && (
                    <div className="h-text-center">
                        <h3 className="h-mb-2">Fitness programy nedostupné:</h3>
                        <div className="h-color-alert h-text-size-large h-text-bold">
                            {apiErrors[fitnessPlanError.response.status as keyof typeof apiErrors]}
                        </div>
                    </div>
                )}

                {isValidating ? (
                    <div className="h-py-5 h-text-center">
                        <span className="loader-spinner" />
                    </div>
                ) : (
                    <div className="fitness-programs-grid">
                        {data
                            ?.filter((program) => program.id !== fitnessPlan?.fitness_program?.id)
                            .map((program, index) => (
                                <FitnessProgramItem
                                    key={program.id}
                                    {...program}
                                    isCurrent={false}
                                    color={(['pink', 'blue', 'purple'] as const)[index % 3]}
                                />
                            ))}
                    </div>
                )}
                <div className="h-mt-3 h-text-center">
                    <a
                        href="https://www.strongbeauty.cz/kviz-vyber-fitness-programu/"
                        className="button is-yellow"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Udělat si kvíz
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FitnessPrograms;

const FitnessProgramItem: (
    props: FitnessProgram & {
        color: 'yellow' | 'pink' | 'blue' | 'purple';
        isCurrent: boolean;
        titleComponent?: JSX.Element;
    } & Omit<React.HTMLAttributes<HTMLDivElement>, 'id'>,
) => JSX.Element = ({ description, id, image, name, weeks, isCurrent, color, titleComponent, className, ...props }) => {
    const [showDescription, setShowDescription] = useState(false);

    return (
        <>
            <div {...props} className={classes([`overview-list is-${color}`, className])}>
                <div className="overview-list-button is-wrapped">{titleComponent || name}</div>
                <div className="overview-list-image">
                    <img src={image} alt={name} />
                    <span className="overview-list-badge">
                        <strong>Délka:</strong> {weeks} týdnů
                    </span>
                </div>
                <ul className="overview-list-list">
                    <li>
                        <button onClick={() => setShowDescription(true)} className="overview-list-list-item">
                            O programu
                            <Chevron className="overview-list-chevron" />
                        </button>
                    </li>
                    <li>
                        <Link
                            to={routesURLs.fitnessProgramForm.replace(':id', id.toString())}
                            className="overview-list-list-item"
                        >
                            {isCurrent ? 'Začít znovu' : 'Zvolit program'}
                            <Chevron className="overview-list-chevron" />
                        </Link>
                    </li>
                </ul>
            </div>
            {showDescription && (
                <Modal isOpen={showDescription} close={() => setShowDescription(false)}>
                    <div className="h-py-2">
                        <h3 className="h-mb-3">{name}</h3>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </Modal>
            )}
        </>
    );
};
